import "./login.css";
import "../../assets/css_variables.css";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import RoutesToPages from "../../RoutesToPages";
import WelcomePage from "../WelcomePage/WelcomePage";
import Inputmask from "inputmask";
import ServerRequest from "../../ServerRequests/ServerRequest";
import HandleLocalData from "../../modules/HandleLocalData";

const LoginPage = () => {
  let linkToLp =
    "https://en.servicelands.com/noa/aidente/general/qwe/lp01_aipsms/";

  const refPageWrapper = useRef(null);
  const inputRef = useRef(null);
  const refLoginSection = useRef(null);
  const navigate = useNavigate();
  const [changedStatus, setChangedStatus] = useState(false);
  const refErrorBox = useRef(null);
  const authCountryCode = useRef(null);

  async function hashUserData(userData) {
    const encoder = new TextEncoder();
    const data = encoder.encode(userData);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  }

  let handleSubmit;

  handleSubmit = async () => {
    localStorage.removeItem("userHash");
    localStorage.removeItem("generated_recipe_today");
    localStorage.removeItem("max_attempts");
    localStorage.removeItem("favoritesHash");

    let countryCode = authCountryCode.current.textContent.slice(1);

    if (inputRef.current) {
      const phoneNumber = inputRef.current.value.replace(/\D/g, ""); // Видаляємо всі нецифрові символи
      const fullNumber = countryCode + phoneNumber;
      let errorString = "";

      if (fullNumber && fullNumber.length === 12) {
        let serverData = await ServerRequest.authenticateUserData(fullNumber);
        let parsedServerData = await serverData.json();

        if (serverData.ok) {
          if (parsedServerData.status) {
            // ServerRequest.getFavoritesRecipes(
            //   parsedServerData.user_hash,
            //   HandleLocalData.prepareAndStoreFavorites
            // )

            localStorage.setItem("userHash", parsedServerData.user_hash);
            localStorage.setItem(
              "generated_recipe_today",
              parsedServerData.used_per_day
            );
            localStorage.setItem(
              "max_attempts",
              parsedServerData.count_per_day
            );

            localStorage.setItem(
              "favoritesHash",
              parsedServerData.favorites_hash
            );

            //згенерувати хеш id з локал сторедж
            const storedId = localStorage.getItem("userHash");

            const data = JSON.parse(localStorage.getItem(storedId));

            if (data) {
              try {
                const id = data.favorites.map((recipe) => recipe.id);
                const uniqueConcatenatedId = id.join(",");

                const result = await hashUserData(uniqueConcatenatedId);

                //порівняти хеш з локал сторедж
                if (result !== parsedServerData.favorites_hash) {
                  // якщо різні - завантажити з бека фаворітс:
                  ServerRequest.getFavoritesRecipes(
                    parsedServerData.user_hash,
                    HandleLocalData.prepareAndStoreFavorites
                  );
                }
              } catch (e) {
                console.error("Error parsing JSON:", e);
              }
            } else {
              const userHash = localStorage.getItem("userHash");

              //якщо немає даних, витягуємо їх із серверу.
              ServerRequest.getFavoritesRecipes(
                parsedServerData.user_hash,
                HandleLocalData.prepareAndStoreFavorites
              );

              localStorage.setItem(
                userHash,
                JSON.stringify({
                  generated_recipe_today: 0,
                  favorites: [],
                })
              );
            }

            redirectToStartingPage();
          }
        } else {
          //console.log(parsedServerData);

          if (parsedServerData.code === 403) {
            errorString = `You don’t have access due to the low balance`;
            showError(errorString);
          } else if (parsedServerData.code === 401) {
            errorString = `This number doesn't have access.<br> Click <a class="error__link" href="${linkToLp}"><strong>here</strong></a> to activate it`;
            showError(errorString);
          } else {
            errorString = `Number verification failed`;
            showError(errorString);
          }
        }
      } else {
        errorString = `Incorrect number`;
        showError(errorString);
      }
    }
  };

  const showError = (string) => {
    inputRef.current.classList.add("error");
    refErrorBox.current.innerHTML = string;
  };

  const applyInputMask = () => {
    if (inputRef.current) {
      const im = new Inputmask("999-999-999");
      im.mask(inputRef.current);
    }

    if (refErrorBox.current) {
      refErrorBox.current.innerHTML = "";
    }
    inputRef.current.classList.remove("error");
  };

  useEffect(() => {
    if (sessionStorage.getItem("isVisitedFirst")) {
      setChangedStatus(true);
    }

    if (refLoginSection.current) {
      if (changedStatus) {
        refLoginSection.current.classList.remove("hide");
      } else {
        refLoginSection.current.classList.add("hide");
      }
    }
  }, [changedStatus]);

  const redirectToStartingPage = () => {
    navigate(RoutesToPages.home);
  };

  return !changedStatus ? (
    <WelcomePage status={changedStatus} onChangedStatus={setChangedStatus} />
  ) : (
    <>
      <section ref={refLoginSection} className="section_wrapper hide">
        <div ref={refPageWrapper} className="autent__page_wrapper">
          <div className="autent__page">
            <div className="autent__logo_wrapper">
              <img
                className="autent__logo"
                src={require("./img/login.png")}
                alt="autent__logo"
              />
              <img
                style={{ display: "none" }}
                className="autent__logo"
                src={require("./img/login.png")}
                alt="autent__logo"
              />
            </div>
            <div id="autent__text" className="autent__page_text">
              Log in with your phone number to create personalized recipes
            </div>

            <div className="autent_number_wrapper">
              <div ref={authCountryCode} className="autent__countrycode">
                +333
              </div>
              <input
                ref={inputRef}
                onInput={applyInputMask}
                className="autent__number"
                id="phoneNumber"
                type="tel"
                placeholder="Enter number"
                inputMode="numeric"
                autoComplete="off"
              />
            </div>

            <div ref={refErrorBox} className="error__box"></div>

            <button className="autent__page_button" onClick={handleSubmit}>
              Continue
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;

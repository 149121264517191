import React, {useRef, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import LoaderPopup from "../../components/LoaderPopup/Loader";
import {CheckAndUpdateRecipeAttempts} from "../../assets/recipeCounter";
import "./recipe.css";
import {parseInput} from "../../assets/helper.js";
import RoutesToPages from "../../RoutesToPages/index.jsx";
import ServerRequest from "../../ServerRequests/ServerRequest";
import {SetConversation} from "../../assets/SetConversation";

const RecipePage = () => {
  let MAX_ATTEMPTS_PER_DAY = +localStorage.getItem("max_attempts");

  const recipeLikeRef = useRef(null);
  const location = useLocation();
  const [postId, setPostId] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loaderPopupRef = useRef(null);
  const [error, setError] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const overlayPage = useRef(null);
  const maxAttemptsPopupRef = useRef(null);
  const maxAttemptsTimeOutRef = useRef(null);
  const body = document.body;
  let {cuisine, recipeData, banner, newPostId} = location.state || [];
  const [parsedData, setParsedData] = useState(recipeData);
  const userHash = localStorage.getItem("userHash");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Використовуйте 'smooth' для плавної прокрутки
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  if (banner === undefined) {
    banner = "breakfast";
    navigate(RoutesToPages.home);
  }

  useEffect(() => {
    if (!location.state) {
      navigate(RoutesToPages.home);
    }

    try {
      let parsedResponse;
      if (recipeData) {
        parsedResponse = parseInput(recipeData);
      }
      setParsedData(parsedResponse);

      if (userHash) {
        let userData = JSON.parse(localStorage.getItem(userHash)) || {
          date: new Date().toLocaleDateString(),
          generated_recipe_today: 0,
          favorites: [],
        };

        const currentDate = new Date().toLocaleDateString();
        if (userData.date !== currentDate) {
          userData.date = currentDate;
          userData.generated_recipe_today = 0;
        }

        if (
          +localStorage.getItem("generated_recipe_today") >=
          MAX_ATTEMPTS_PER_DAY
        ) {
          setIsButtonDisabled(true);
        } else {
          setIsButtonDisabled(false);
        }

        const isRecipeLiked = userData.favorites.some(
          (fav) => fav.id === newPostId?.toString()
        );
        setIsLiked(isRecipeLiked);
      }
    } catch (error) {
      console.error(error.message);
    }

    setPostId(newPostId);
  }, [parsedData, newPostId]);

  const handleClosePopup = (e) => {
    let target = e.target;
    target.closest(".popup__united").classList.add("fadeOut");
    target.closest(".popup__united").classList.remove("shown", "fadeOut");
    removePopup();

    if (maxAttemptsTimeOutRef.current) {
      clearTimeout(maxAttemptsTimeOutRef.current);
    }
  };

  useEffect(() => {
    if (recipeLikeRef.current && postId !== null) {
      recipeLikeRef.current.dataset.id = postId;
    }
  }, [postId]);

  const handleChangeLike = () => {
    const userHash = localStorage.getItem("userHash");
    if (!userHash) {
      //console.log("User hash not found");
      return;
    }

    let userData = JSON.parse(localStorage.getItem(userHash)) || {
      date: new Date().toLocaleDateString(),
      generated_recipe_today: 0,
      favorites: [],
    };

    const recipeId = recipeLikeRef.current.dataset.id;

    if (!isLiked) {
      if (!userData.favorites.some((fav) => fav.id === recipeId)) {
        userData.favorites.unshift({
          liked: true,
          id: recipeId,
          image: banner,
          title: parsedData.title,
          time_preparing: parsedData.time_preparing,
          ingredients: parsedData.ingredients,
          instructions: parsedData.instructions,
          cuisine: cuisine,
        });
      }
    } else {
      userData.favorites = userData.favorites.filter(
        (fav) => fav.id !== recipeId
      );
    }

    setIsLiked(!isLiked);
    localStorage.setItem(userHash, JSON.stringify(userData));
  };

  useEffect(() => {
    const changeLikeStatus = async () => {
      try {
        await ServerRequest.changeFavoriteRecipeData(newPostId, isLiked);
      } catch (error) {
        console.error("Error changing like status:", error);
      }
    };

    changeLikeStatus();
  }, [isLiked, newPostId]);

  const addPopup = (activePopup) => {
    activePopup.classList.add("shown");
    overlayPage.current.classList.add("active");

    document.body.querySelector(".header__wrapper").classList.add("blurred");
    document.body.querySelector(".recipe__section").classList.add("blurred");
    document.body.style.overflow = "hidden";
  };

  const removePopup = (activePopup) => {
    if (activePopup) {
      activePopup.classList.remove("shown");
    }

    overlayPage.current.classList.remove("active");
    document.body.querySelector(".header__wrapper").classList.remove("blurred");
    document.body.querySelector(".recipe__section").classList.remove("blurred");

    document.body.style.overflow = "";
  };

  const generateAnotherRecipe = async (e) => {
    if (isButtonDisabled) {
      //якщо кнопка disabled - тоді нічо не робим для нового запиту, а повертаємо попап
      addPopup(maxAttemptsPopupRef.current);

      if (maxAttemptsTimeOutRef.current) {
        clearTimeout(maxAttemptsTimeOutRef.current);
      }

      maxAttemptsTimeOutRef.current = setTimeout(() => {
        removePopup(maxAttemptsPopupRef.current);
      }, 4100);

      return false;
    }

    e.preventDefault();

    const lastQuery = JSON.parse(localStorage.getItem("last_query")) || false;
    if (lastQuery) {
      setLoading(true);
      body.style.overflow = "hidden";

      try {
        let getServerData = await ServerRequest.createRecipeData(
          banner,
          "recipe",
          lastQuery, // lastQuery буде таким як останній згенерований recipeData із preferences
          userHash,
          JSON.parse(localStorage.getItem("conversation"))
        );
        let createdRecipeData = await getServerData.json();

        if (getServerData.ok) {
          // Перевірка статусу відповіді
          if (createdRecipeData.code === 200) {
            scrollToTop();

            const obj = {
              //cuisineType: cuisine,
              recipeData: parseInput(createdRecipeData.message),
              banner: banner,
              newPostId: createdRecipeData.external_id,
            };

            localStorage.setItem(
              "generated_recipe_today",
              createdRecipeData.used_per_day
            );

            SetConversation(obj.recipeData.title);
            CheckAndUpdateRecipeAttempts(createdRecipeData.used_per_day);

            navigate(window.location.pathname, {
              replace: true,
              state: {
                cuisine: cuisine,
                recipeData: obj.recipeData,
                banner: obj.banner,
                newPostId: obj.newPostId,
              },
            });

            body.style.overflow = "visible";
          } else {
            alert("AI too busy for now, please, try again)");
            setError("Unexpected response status: " + createdRecipeData.status);
          }
        }
      } catch (err) {
        setError("Failed to fetch response");
      } finally {
        setLoading(false);
      }
    } else {
      alert("Something went wrong 8-( ");
    }
  };

  useEffect(() => {
    if (loading) {
      loaderPopupRef.current.showLoader();
      body.style.overflow = "hidden";
    } else {
      loaderPopupRef.current.hideLoader();
      body.style.overflow = "visible";
    }
  }, [loading]);

  return (
    <>
      <div ref={overlayPage} className="overlay">
        <div ref={maxAttemptsPopupRef} className="popup__united hidden">
          <div onClick={handleClosePopup} className="popup__close">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              className="popup__progress_circle"
            >
              <circle className="bg"></circle>
              <circle className="fg"></circle>
            </svg>
          </div>
          <h3 className="popup__title">
            Opps, you've reached the daily limit.
          </h3>
          <span className="popup__text">Come back tomorrow and try again.</span>
        </div>
      </div>

      <section className="recipe__section">
        <div className="recipe__page_wrapper">
          <LoaderPopup ref={loaderPopupRef}/>

          <div className="recipe__banner_wrapper">
            <img
              className="recipe__banner"
              src={require(`./img/${banner}.png`)}
              alt="recipe__logo"
            />
          </div>

          <div className="recipe__content">
            {parsedData ? (
              <>
                <h1 className="recipe__title">{parsedData.title}</h1>
                {cuisine ? <span className='recipe__cuisine'>{`${cuisine} cuisine`}</span> : null}
                <div className="interactive__elements">
                  <div className="recipe__timer">
                    {parsedData.time_preparing}
                  </div>
                  <div
                    className={`recipe__like ${isLiked ? "recipe__liked" : ""}`}
                    ref={recipeLikeRef}
                    onClick={handleChangeLike}
                  ></div>
                </div>
                <h2 className="ingredients__title">Ingredients</h2>
                <ul>
                  {parsedData.ingredients.map((ingredient, index) => (
                    <li className="ul" key={index}>
                      {ingredient}
                    </li>
                  ))}
                </ul>
                <h2 className="instructions__title">Instructions</h2>
                <ol>
                  {parsedData.instructions.map((instruction, index) => (
                    <li className="ol" key={index}>
                      {instruction}
                    </li>
                  ))}
                </ol>
              </>
            ) : (
              <p>There is no relative recipe ((</p>
            )}
          </div>

          <div id="recipe__message" className="recipe__message">
            I'm just an AI. There might be mistakes in what I generate. Please
            check recipes before cooking.
          </div>

          <div className="pseudo__form" style={{display: "flex"}}>
            <button
              type="submit"
              className="recipe__page_button"
              onClick={() => {
                navigate(RoutesToPages.home);
              }}
            >
              Finish
            </button>
          </div>
          <div className="form">
            <button
              className={`generate__new ${isButtonDisabled ? "disabled" : ""}`}
              onClick={generateAnotherRecipe}
            >
              Generate a new
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecipePage;

import "./welcome.css";
import { useRef, useEffect } from "react";

const WelcomePage = (props) => {
  const myWelcomeRef = useRef(null);
  const myWelcomeTextRef = useRef(null);

  useEffect(() => {
    let welcomeTextTimer;

    welcomeTextTimer = setTimeout(() => {
      myWelcomeTextRef.current.classList.remove("transparent");
    }, 800);

    const welcomePageTimer = setTimeout(() => {
      myWelcomeRef.current.classList.add("hide");
      props.onChangedStatus(true);
    }, 1500);

    return () => {
      clearTimeout(welcomePageTimer);
      clearTimeout(welcomeTextTimer);
    };
  }, []);

  return (
    <div ref={myWelcomeRef} className="welcome__page_wrapper">
      <div className="welcome__page">
        <div className="welcome__logo_wrapper">
          <img
            className="welcome__logo"
            src={require("./img/logo.png")}
            alt="welcome__logo"
          />

          <img
            style={{ display: "none" }}
            className="starting__logo"
            src={require("../../pages/StartingPage/img/start.png")}
            alt="starting__logo"
          />
        </div>
        <div ref={myWelcomeTextRef} className="welcome__text transparent">
          Hello! I am your Ai-powered kitchen assistant
          <span className="icon_chief"></span>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;

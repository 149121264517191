import "./preferences.css";
import React, { useRef, useEffect, useState } from "react";
import arrayIngredients from "../../assets/list_ingredients.json";
import arrayAllergens from "../../assets/list_allergic.json";
import svgIcon from "./img/close.svg";
import svgIconGrey from "./img/close_gray.svg";
import DishType from "../../components/DishType/DishType";
import LoaderPopup from "../../components/LoaderPopup/Loader";
import { useNavigate } from "react-router-dom";
import { CheckAndUpdateRecipeAttempts } from "../../assets/recipeCounter";
import RoutesToPages from "../../RoutesToPages";
import { parseInput } from "../../assets/helper.js";
import cuisineIcon from "./img/cuisine.svg";
import ServerRequest from "../../ServerRequests/ServerRequest";
import { SetConversation } from "../../assets/SetConversation";

const Preferences = () => {
  let MAX_ATTEMPTS_PER_DAY = +localStorage.getItem("max_attempts");

  const [allergiesValue, setAllergiesValue] = useState("");
  const [disabledAllergensInput, setDisabledAllergensInput] = useState(false);
  const [filteredAllergens, setFilteredAllergens] = useState([]);
  const [allergensToRender, setAllergensToRender] = useState([]);
  const allergiesQuantityRef = useRef(null);
  const allergensListRef = useRef(null);
  const allergenRef = useRef(null);
  const inputAllergiesWrapperRef = useRef(null);
  const [ingredientsValue, setIngredientsValue] = useState("");
  const [disabledIngredientsInput, setDisabledIngredientsInput] =
    useState(false);
  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const [ingredientsToRender, setIngredientsToRender] = useState([]);
  const ingredientsQuantityRef = useRef(null);
  const ingredientsListRef = useRef(null);
  const ingredientRef = useRef(null);
  const searchedIngredientsRef = useRef(null);
  const inputIngredientsWrapperRef = useRef(null);
  const dishTypeRef = useRef(null);
  const loaderPopupRef = useRef(null);
  const [selectedDish, setSelectedDish] = useState("");
  const dietRef = useRef(null);
  const [chosenDiet, setChosenDiet] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [queryClicked, setQueryClicked] = useState(false);
  const [recipesData, setRecipesData] = useState({});
  const visibilityGeneralPopupRef = useRef(null);
  const overlayPage = useRef(null);
  const visibilityConflictingItemsRef = useRef(null);
  const maxAttemptsPopupRef = useRef(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showUnselectedItemsPopup, setShowUnselectedItemsPopup] = useState("");
  const [conflicts, setConflicts] = useState({
    diets: [],
    allergies: [],
    ingredients: [],
  });
  const refConflictsPopup = useRef(null);
  const refUnselectedPopup = useRef(null);
  const maxAttemptsTimeOutRef = useRef(null);
  const inputIngredientsRef = useRef(null);
  const searchAllergiesRef = useRef(null);
  const clearedAllergiesRef = useRef(null);
  const allergiesWarningRef = useRef(null);
  const clearedIngredientsRef = useRef(null);
  const ingredientsWarningRef = useRef(null);
  const [warningAllergies, setWarningAllergies] = useState(false);
  const [warningIngredients, setWarningIngredients] = useState(false);
  const resetRef = useRef(null);
  const navigate = useNavigate();
  const [dishTypeBanner, setDishTypeBanner] = useState("");
  const [selectedCuisine, setSelectedCuisine] = useState("");
  const cuisineRef = useRef(null);
  let userHash = localStorage.getItem("userHash");

  const handleClosePopup = (e) => {
    let target = e.target;
    target.closest(".popup__united").classList.add("fadeOut");
    target.closest(".popup__united").classList.remove("shown", "fadeOut");

    removePopup();

    if (refConflictsPopup.current) {
      clearTimeout(refConflictsPopup.current);
    }

    if (refUnselectedPopup.current) {
      clearTimeout(refUnselectedPopup.current);
    }

    if (maxAttemptsTimeOutRef.current) {
      clearTimeout(maxAttemptsTimeOutRef.current);
    }
  };
  const handleChooseDish = ({ target }) => {
    let dataTypeDish;
    Array.from(dishTypeRef.current.children).forEach((element) => {
      if (target === element) {
        target.classList.add("chosen__button");
        setSelectedDish(target.textContent);
        dataTypeDish = target.classList.contains("chosen__button")
          ? target.dataset.dish
          : "";
        setDishTypeBanner(dataTypeDish);
      } else {
        element.classList.remove("chosen__button");
      }
    });
  };
  const handleChooseCuisine = (event) => {
    const { target } = event || {};
    if (!target) {
      return;
    }

    let typeCuisine;
    Array.from(cuisineRef.current.children).forEach((element) => {
      if (target === element) {
        target.classList.toggle("chosen__button");

        if (target.classList.contains("chosen__button")) {
          setSelectedCuisine(target.textContent);
        } else {
          setSelectedCuisine("");
        }

        typeCuisine = target.classList.contains("chosen__button")
          ? target.dataset.dish
          : "";
      } else {
        element.classList.remove("chosen__button");
      }
    });
  };
  const handleChooseDiet = (event) => {
    const { target } = event || {};
    if (!target) {
      return;
    }

    Array.from(dietRef.current.children).forEach((element) => {
      if (target === element) {
        target.classList.toggle("chosen__button");

        if (target.classList.contains("chosen__button")) {
          setChosenDiet(target.textContent);
        } else {
          setChosenDiet("");
        }
      } else {
        element.classList.remove("chosen__button");
      }
    });
  };

  const handleSearchAllergens = ({ target }) => {
    const trimmedValue = target.value.trimStart();
    setAllergiesValue(trimmedValue);

    if (target.value.length > 1) {
      allergensListRef.current.classList.remove("hide");

      let arrayUnselectedAllergens = [];

      if (allergensToRender.length > 0) {
        arrayUnselectedAllergens = arrayAllergens.filter(
          (elem) => !allergensToRender.includes(elem)
        );
      } else {
        arrayUnselectedAllergens = arrayAllergens;
      }

      const temporaryFilteredAllergens = arrayUnselectedAllergens
        .filter((item) => {
          const allKeyWords = item
            .split(" ")
            .some((word) =>
              target.value.toLowerCase().includes(word.toLowerCase())
            );
          if (target.value.toLowerCase().includes(" ") && allKeyWords) {
            return item.toLowerCase().includes(target.value.toLowerCase());
          } else {
            return item
              .toLowerCase()
              .split(" ")
              .some((word) => word.startsWith(target.value.toLowerCase()));
          }
        })
        .sort((cur, prev) => cur.localeCompare(prev));

      setFilteredAllergens(temporaryFilteredAllergens);
    } else {
      allergensListRef.current.classList.add("hide");
      setFilteredAllergens([]);
    }
  };
  const removeAllergiesValue = () => {
    setAllergiesValue("");
    clearedAllergiesRef.current.classList.remove("activated");
    allergiesWarningRef.current.classList.remove("activated-allergies-warning");
    searchAllergiesRef.current.classList.remove("wrong__word");
  };
  const createAllergenElem = (e) => {
    const target = e.target.closest(".filtered__allergen"); // Переконуємось, що ми працюємо з li

    if (target) {
      if (!allergensToRender.includes(target.textContent)) {
        setAllergensToRender([target.textContent, ...allergensToRender]);
      }
    }

    setAllergiesValue("");
    allergensListRef.current.classList.add("hide");
  };
  const createItemAllergensList = () => {
    return allergensToRender.map((item, index) => (
      <button
        className="btn__allergen"
        onClick={() => removeItem(allergensToRender, index)}
        key={index}
      >
        {item}
      </button>
    ));
  };
  const resetAllergens = () => {
    setAllergiesValue("");
    setFilteredAllergens([]);
    setAllergensToRender([]);
    allergensListRef.current.classList.add("hide");
    sessionStorage.removeItem("allergens");
  };
  const highlightAllergens = () => {
    const allergensToRenderedChildren = Array.from(
      allergenRef.current.children
    );

    allergensToRenderedChildren.forEach((allergen) =>
      allergen.classList.remove("excluded")
    );

    if (allergensToRender.length > 0 && ingredientsToRender.length > 0) {
      const allergensForSelectedIngredients = arrayIngredients
        .filter((item) => ingredientsToRender.includes(item.name))
        .map((item) => item.allergy || [])
        .flat();

      const uniqueAllergensList = Array.from(
        new Set(
          allergensForSelectedIngredients
            .map((item) => item.toLowerCase())
            .join(" ")
            .split(" ")
        )
      );

      allergensToRenderedChildren.forEach((item) => {
        const separateAllergenToRenderList = item.textContent
          .toLowerCase()
          .split(" ");

        if (
          separateAllergenToRenderList.some((item) =>
            uniqueAllergensList.includes(item)
          )
        ) {
          item.classList.add("excluded");
        }
      });
    }
  };

  useEffect(() => {
    if (allergiesValue.length <= 1) {
      allergensListRef.current.classList.add("hide");
      setFilteredAllergens([]);
    }
  }, [allergiesValue, allergensListRef]);

  const handleSearchIngredients = ({ target }) => {
    const trimmedValue = target.value.trimStart();
    setIngredientsValue(trimmedValue);

    let temporaryFilteredIngredients;

    if (target.value.length > 1) {
      ingredientsListRef.current.classList.remove("hide");

      let arrayUnselectedIngredients = [];

      if (ingredientsToRender.length > 0) {
        arrayUnselectedIngredients = arrayIngredients.filter(
          (elem) => !ingredientsToRender.includes(elem.name)
        );
      } else {
        arrayUnselectedIngredients = arrayIngredients;
      }

      temporaryFilteredIngredients = arrayUnselectedIngredients
        .map((item) => item.name.toLowerCase())
        .filter((el) => {
          const allKeyWords = el
            .split(" ")
            .some((word) => target.value.toLowerCase().includes(word));
          if (target.value.toLowerCase().includes(" ") && allKeyWords) {
            return el.includes(target.value.toLowerCase());
          } else {
            return el
              .split(" ")
              .some((word) => word.startsWith(target.value.toLowerCase()));
          }
        })
        .sort((cur, prev) => cur.localeCompare(prev));

      setFilteredIngredients(temporaryFilteredIngredients);
    } else {
      ingredientsListRef.current.classList.add("hide");
      setFilteredIngredients([]);
    }
  };
  const removeIngredientsValue = () => {
    setIngredientsValue("");
    clearedIngredientsRef.current.classList.remove("activated");
    ingredientsWarningRef.current.classList.remove(
      "activated-allergies-warning"
    );
    searchedIngredientsRef.current.classList.remove("wrong__word");
  };
  useEffect(() => {
    if (ingredientsValue.length > 1 && filteredIngredients.length === 0) {
      searchedIngredientsRef.current.classList.add("wrong__word");
      clearedIngredientsRef.current.classList.add("activated");
      ingredientsWarningRef.current.classList.add(
        "activated-allergies-warning"
      );
      setWarningIngredients(true);
    } else {
      searchedIngredientsRef.current.classList.remove("wrong__word");
      clearedIngredientsRef.current.classList.remove("activated");
      ingredientsWarningRef.current.classList.remove(
        "activated-allergies-warning"
      );
      setWarningIngredients(false);
    }

    if (allergiesValue.length > 1 && filteredAllergens.length === 0) {
      searchAllergiesRef.current.classList.add("wrong__word");
      clearedAllergiesRef.current.classList.add("activated");
      allergiesWarningRef.current.classList.add("activated-allergies-warning");
      setWarningAllergies(true);
    } else {
      searchAllergiesRef.current.classList.remove("wrong__word");
      clearedAllergiesRef.current.classList.remove("activated");
      allergiesWarningRef.current.classList.remove(
        "activated-allergies-warning"
      );
      setWarningAllergies(false);
    }
  }, [
    filteredIngredients,
    ingredientsValue,
    filteredAllergens,
    allergiesValue,
  ]);
  const createIngredientElem = (e) => {
    const target = e.target.closest(".filtered__ingredient"); // Переконуємось, що ми працюємо з li

    if (target) {
      if (!ingredientsToRender.includes(target.textContent)) {
        setIngredientsToRender([target.textContent, ...ingredientsToRender]);
      }
    }

    setIngredientsValue("");
    ingredientsListRef.current.classList.add("hide");
  };
  const createItemIngredientsList = () => {
    return ingredientsToRender.map((item, index) => (
      <button
        className="btn__ingredient"
        onClick={() => removeItem(ingredientsToRender, index)}
        key={index}
      >
        {item}
      </button>
    ));
  };
  const resetIngredients = () => {
    setIngredientsValue("");
    setFilteredIngredients([]);
    setIngredientsToRender([]);
    ingredientsListRef.current.classList.add("hide");
    sessionStorage.removeItem("ingradients");
  };
  const highlightIngredients = () => {
    const ingredientsToRenderedChildren = Array.from(
      ingredientRef.current.children
    );

    ingredientsToRenderedChildren.forEach((ingredient) =>
      ingredient.classList.remove("excluded")
    );

    const dietChildren = Array.from(dietRef.current.children);

    dietChildren.forEach((el) => {
      el.classList.remove("excluded");
    });

    if (ingredientsToRender.length > 0 && chosenDiet) {
      const ingredientsToExclude = arrayIngredients
        .filter((item) => !item.diet.includes(chosenDiet))
        .map((item) => item.name);

      ingredientsToRenderedChildren.forEach((ingredient) => {
        const ingredientName = ingredient.textContent;

        if (ingredientsToExclude.includes(ingredientName)) {
          ingredient.classList.add("excluded");

          dietChildren.forEach((diet) => {
            const dietName = diet.textContent;

            if (dietName.includes(chosenDiet)) {
              diet.classList.add("excluded");
            }
          });
        }
      });
    }

    if (allergensToRender.length > 0 && ingredientsToRender.length > 0) {
      const matchedAllergens = arrayIngredients
        .filter((item) => ingredientsToRender.includes(item.name))
        .map((item) => {
          return {
            name: item.name,
            allergy: item.allergy
              .map((el) => el.toLowerCase())
              .join(" ")
              .split(" "),
          };
        });

      const lowerCaseAllergensToRender = Array.from(
        new Set(
          allergensToRender
            .map((el) => el.toLowerCase())
            .join(" ")
            .split(" ")
        )
      );

      const excludedElems = matchedAllergens.filter((item) =>
        item.allergy.some((allergy) =>
          lowerCaseAllergensToRender.includes(allergy)
        )
      );

      ingredientsToRenderedChildren.forEach((item) => {
        const ingredientText = item.textContent.toLowerCase();

        if (
          excludedElems.some((item) =>
            item.name.toLowerCase().includes(ingredientText)
          )
        ) {
          item.classList.add("excluded");
        }
      });
    }
  };

  useEffect(() => {
    if (userHash) {
      const userHashDataString = localStorage.getItem(userHash);
      const userHashData = JSON.parse(userHashDataString);
      const userAttempts = userHashData.generated_recipe_today;

      const counterNumber = localStorage.getItem("generated_recipe_today"); //+userAttempts;
      userHashData.generated_recipe_today = userAttempts;
      localStorage.setItem(userHash, JSON.stringify(userHashData));

      if (counterNumber >= MAX_ATTEMPTS_PER_DAY) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
        resetRef.current.classList.remove("disabled_reset");
        inputIngredientsRef.current.style.pointerEvents = "auto";
      }
    } else {
      //console.log("User hash not found in local storage");
    }
  }, []);

  useEffect(() => {
    if (ingredientsValue.length <= 1) {
      ingredientsListRef.current.classList.add("hide");
      setFilteredIngredients([]);
    }
  }, [ingredientsValue, ingredientsListRef]);

  const highlightExcludedElems = () => {
    highlightAllergens();
    highlightIngredients();
  };
  useEffect(() => {
    highlightExcludedElems();
  }, [allergensToRender, ingredientsToRender, chosenDiet]);

  const removeItem = (list, indexItem) => {
    const updatedArray = list.filter((item, index) => index !== indexItem);

    list === allergensToRender
      ? setAllergensToRender(updatedArray)
      : setIngredientsToRender(updatedArray);
  };

  const resetPreferences = () => {
    handleChooseDish({});
    setSelectedDish("");
    handleChooseCuisine({});
    setSelectedCuisine("");
    Array.from(cuisineRef.current.children).forEach((item) =>
      item.classList.remove("chosen__button")
    );
    handleChooseDiet({});
    setChosenDiet("");
    Array.from(dietRef.current.children).forEach((item) =>
      item.classList.remove("chosen__button")
    );
    resetAllergens();
    resetIngredients();
    setRecipesData({});
    setConflicts({ diets: [], allergies: [], ingredients: [] });
    setQueryClicked(false);
  };

  const handleOnLoadCheckData = () => {
    setQueryClicked(true);

    const dishChildren = Array.from(dishTypeRef.current.children);

    if (
      dishChildren.every(
        (item) => !item.classList.contains("chosen__button")
      ) &&
      ingredientsToRender.length < 1
    ) {
      setShowUnselectedItemsPopup("all");
    } else if (
      dishChildren.every((item) => !item.classList.contains("chosen__button"))
    ) {
      setShowUnselectedItemsPopup("dish");
    } else if (ingredientsToRender.length < 1) {
      setShowUnselectedItemsPopup("ingredients");
    } else if (
      dishChildren.some((item) => item.classList.contains("chosen__button")) &&
      ingredientsToRender.length >= 1
    ) {
      setShowUnselectedItemsPopup("");
    }

    if (
      (!selectedDish && ingredientsToRender.length < 1) ||
      !selectedDish ||
      ingredientsToRender.length < 1
    ) {
      checkUnselectedData();
    } else {
      conflictingItems();
      checkConflictingItems();
    }
  };

  useEffect(() => {
    setRecipesData({
      cuisine: selectedCuisine || null,
      diet: chosenDiet || null,
      allergies: allergensToRender || null,
      ingredients: ingredientsToRender,
    });
  }, [chosenDiet, allergensToRender, ingredientsToRender]);

  const addPopup = (activePopup) => {
    activePopup.classList.add("shown");
    overlayPage.current.classList.add("active");

    document.body.querySelector(".header__wrapper").classList.add("blurred");
    document.body
      .querySelector(".preferences__page_wrapper")
      .classList.add("blurred");

    document.body.style.overflow = "hidden";
  };

  const removePopup = (activePopup) => {
    if (activePopup) {
      activePopup.classList.remove("shown");
    }

    overlayPage.current.classList.remove("active");

    document.body.querySelector(".header__wrapper").classList.remove("blurred");
    document.body
      .querySelector(".preferences__page_wrapper")
      .classList.remove("blurred");

    document.body.style.overflow = "";
  };

  const checkUnselectedData = () => {
    if (!isButtonDisabled) {
      if (
        showUnselectedItemsPopup === "all" ||
        showUnselectedItemsPopup === "dish" ||
        showUnselectedItemsPopup === "ingredients"
      ) {
        addPopup(visibilityGeneralPopupRef.current);

        if (refUnselectedPopup.current) {
          clearTimeout(refUnselectedPopup.current);
        }

        refUnselectedPopup.current = setTimeout(() => {
          removePopup(visibilityGeneralPopupRef.current);
        }, 4100);
      } else if (showUnselectedItemsPopup === "") {
        removePopup(visibilityGeneralPopupRef.current);
      }
    }
  };

  useEffect(() => {
    const dishChildren = Array.from(dishTypeRef.current.children);
    if (queryClicked === true) {
      dishChildren.forEach((el) => {
        if (
          dishChildren.every(
            (item) => !item.classList.contains("chosen__button")
          )
        ) {
          el.classList.add("unselected__dish");
        } else {
          el.classList.remove("unselected__dish");
        }
      });

      if (ingredientsToRender.length < 1) {
        searchedIngredientsRef.current.classList.add("empty__field");
      } else {
        searchedIngredientsRef.current.classList.remove("empty__field");
      }
    } else {
      dishChildren.forEach((dish) => dish.classList.remove("unselected__dish"));
      searchedIngredientsRef.current.classList.remove("empty__field");
    }
  }, [selectedDish, ingredientsToRender, queryClicked]);

  useEffect(() => {
    checkUnselectedData();
  }, [showUnselectedItemsPopup]);

  useEffect(() => {
    allergiesQuantityRef.current.textContent = allergensToRender.length;
    ingredientsQuantityRef.current.textContent = ingredientsToRender.length;

    if (allergensToRender.length >= 5) {
      setDisabledAllergensInput(true);
      inputAllergiesWrapperRef.current.classList.add("input__transparent");
    } else {
      setDisabledAllergensInput(false);
      inputAllergiesWrapperRef.current.classList.remove("input__transparent");
    }

    if (ingredientsToRender.length >= 5) {
      setDisabledIngredientsInput(true);
      inputIngredientsWrapperRef.current.classList.add("input__transparent");
    } else {
      setDisabledIngredientsInput(false);
      inputIngredientsWrapperRef.current.classList.remove("input__transparent");
    }
  }, [allergensToRender, ingredientsToRender]);

  const conflictingItems = () => {
    const excludedDiets = Array.from(dietRef.current.children)
      .filter((item) => item.classList.contains("excluded"))
      .map((item) => item.textContent.toLowerCase());

    const excludedAllergies = Array.from(allergenRef.current.children)
      .filter((item) => item.classList.contains("excluded"))
      .map((item) => item.textContent.trim().toLowerCase());

    const excludedIngredients = Array.from(ingredientRef.current.children)
      .filter((item) => item.classList.contains("excluded"))
      .map((item) => item.textContent.trim().toLowerCase());

    const excludedItems = {
      diets: excludedDiets,
      allergies: excludedAllergies,
      ingredients: excludedIngredients,
    };

    setConflicts(excludedItems);
    return excludedItems;
  };
  const checkConflictingItems = () => {
    if (
      (conflicts.allergies.length > 0 && conflicts.ingredients.length > 0) ||
      conflicts.diets.length > 0
    ) {
      addPopup(visibilityConflictingItemsRef.current);

      if (refConflictsPopup.current) {
        clearTimeout(refConflictsPopup.current);
      }

      refConflictsPopup.current = setTimeout(() => {
        removePopup(visibilityConflictingItemsRef.current);
      }, 4110);
    } else {
      removePopup(visibilityConflictingItemsRef.current);
    }
  };

  useEffect(() => {
    checkConflictingItems();
  }, [conflicts]);

  const requestGPT = async () => {
    setLoading(true);
    setError(null);

    try {
      let getServerData = await ServerRequest.createRecipeData(
        dishTypeBanner,
        "preferences",
        recipesData,
        userHash,
        [""]
      );
      let createdRecipeData = await getServerData.json();

      // Зберігаємо останній запит у локальному сховищі
      localStorage.setItem("last_query", JSON.stringify(recipesData));

      if (getServerData.ok) {
        // Перевірка статусу відповіді
        if (createdRecipeData.code === 200) {
          const parsedResponse = parseInput(createdRecipeData.message);

          localStorage.setItem(
            "generated_recipe_today",
            createdRecipeData.used_per_day
          );

          CheckAndUpdateRecipeAttempts(createdRecipeData.used_per_day);

          navigate(RoutesToPages.recipe, {
            state: {
              cuisine: selectedCuisine,
              recipeData: parsedResponse,
              banner: dishTypeBanner,
              newPostId: createdRecipeData.external_id,
            },
          });

          SetConversation(parsedResponse.title);
        } else {
          setError("Unexpected response status: " + createdRecipeData.status);
        }
      } else {
      }
    } catch (err) {
      setError("Failed to fetch response");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isButtonDisabled) {
      //якщо кнопка disabled - тоді нічо не робим для нового запиту, а повертаємо попап
      addPopup(maxAttemptsPopupRef.current);

      inputIngredientsRef.current.style.pointerEvents = "none";
      if (maxAttemptsTimeOutRef.current) {
        clearTimeout(maxAttemptsTimeOutRef.current);
      }

      maxAttemptsTimeOutRef.current = setTimeout(() => {
        removePopup(maxAttemptsPopupRef.current);
      }, 4100);

      return false;
    }

    if (
      conflicts.diets.length < 1 &&
      conflicts.allergies.length < 1 &&
      conflicts.ingredients.length < 1 &&
      selectedDish &&
      ingredientsToRender.length > 0
    ) {
      if (!warningIngredients) {
        if (!warningIngredients && !warningAllergies) {
          await requestGPT();
        }
      }
    }
  };

  useEffect(() => {
    if (isButtonDisabled) {
      resetRef.current.classList.add("disabled_reset");
      inputIngredientsRef.current.style.pointerEvents = "none";
    }
  }, [isButtonDisabled]);

  useEffect(() => {
    // Виклик методу showLoader для показу LoaderPopup
    const body = document.body;
    if (loading) {
      body.style.overflow = "hidden";
      loaderPopupRef.current.showLoader();
    } else {
      body.style.overflow = "visible"; //замість visible - щоб не додавало полосу прокрутки коли появляється попап про помилку
      loaderPopupRef.current.hideLoader();
    }
  }, [loading]);

  return (
    <>
      <div className="preferences__page_wrapper">
        <LoaderPopup ref={loaderPopupRef} />

        <div className="preferences__page">
          <h2 className="preferences__main_title">Choose your preferences</h2>

          <DishType ref={dishTypeRef} onChooseDish={handleChooseDish} />

          <div className="preference__box preferences__cuisine">
            <h3 className="preference__title">
              <span className="icon">
                <img src={cuisineIcon} alt="cuisine icon" />
              </span>
              Cuisine
            </h3>
            <div
              ref={cuisineRef}
              onClick={handleChooseCuisine}
              className="preference__button_wrapper"
              data-cuisine
            >
              <button
                data-cuisine="latin american"
                className="preference__button"
              >
                Latin American
              </button>
              <button
                data-cuisine="middle eastern"
                className="preference__button"
              >
                Middle Eastern
              </button>
              <button data-cuisine="african" className="preference__button">
                African
              </button>
              <button data-cuisine="european" className="preference__button">
                European
              </button>
              <button data-cuisine="asian" className="preference__button">
                Asian
              </button>
            </div>
          </div>

          <div className="preference__box preferences__diet">
            <h3 className="preference__title">
              <span className="icon">
                <img src={require("./img/avocado.png")} alt="diet icon" />
              </span>
              Do you follow any diet?
            </h3>
            <div
              ref={dietRef}
              onClick={handleChooseDiet}
              className="preference__button_wrapper"
              data-diet
            >
              <button data-diet="vegetarian" className="preference__button">
                Vegetarian
              </button>
              <button data-diet="vegan" className="preference__button">
                Vegan
              </button>
              <button data-diet="keto" className="preference__button">
                Keto
              </button>
              <button data-diet="low-carb" className="preference__button">
                Low-Carb
              </button>
              <button data-diet="paleo" className="preference__button">
                Paleo
              </button>
              <button data-diet="gluten-free" className="preference__button">
                Gluten Free
              </button>
            </div>
          </div>

          <div className="preference__box preferences__allergic">
            <h3 className="preference__title">
              <span className="icon">
                <img src={require("./img/ostreida.png")} alt="Allerginc icon" />
              </span>
              <span className="preference__title-content">
                Any allergies or intolerance?
                <span className="preference__quantity">
                  (<span ref={allergiesQuantityRef}>0</span>/5)
                </span>
              </span>
            </h3>

            <div ref={searchAllergiesRef} className="preference__input_wrapper">
              <div ref={inputAllergiesWrapperRef} className="input__wrapper">
                <button className="preference__search_button">
                  <img
                    className="preference__search_icon"
                    src={require("./img/search.png")}
                    alt="Search icon"
                  />
                </button>

                <input
                  disabled={disabledAllergensInput}
                  onChange={handleSearchAllergens}
                  value={allergiesValue}
                  placeholder="Search"
                  className="preference__search_input"
                  type="text"
                />

                <span
                  ref={clearedAllergiesRef}
                  onClick={removeAllergiesValue}
                  className="cleared-value"
                ></span>
              </div>
              <div ref={allergenRef} className="allergens">
                {allergensToRender.length > 0
                  ? createItemAllergensList()
                  : null}
              </div>
            </div>

            <div
              ref={allergensListRef}
              onClick={createAllergenElem}
              className="preference__variables_list hide"
            >
              <ul className="filtered__allergen_wrapper">
                {filteredAllergens.map((item, index) => (
                  <li className="filtered__allergen" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <p ref={allergiesWarningRef} className="existed-allergies__warning">
              No match found. Search again
            </p>
          </div>

          <div className="preference__box preferences__ingredients">
            <h3 className="preference__title">
              <span className="icon">
                <img
                  src={require("./img/zucchini.png")}
                  alt="ingredients icon"
                />
              </span>
              <span className="preference__title-content">
                Ingredients
                <span className="preference__quantity">
                  (<span ref={ingredientsQuantityRef}>0</span>/5)
                </span>
              </span>
            </h3>

            <div
              ref={searchedIngredientsRef}
              className="preference__input_wrapper"
            >
              <div ref={inputIngredientsWrapperRef} className="input__wrapper">
                <button className="preference__search_button">
                  <img
                    className="preference__search_icon"
                    src={require("./img/search.png")}
                    alt="Search icon"
                  />
                </button>

                <input
                  ref={inputIngredientsRef}
                  disabled={disabledIngredientsInput}
                  onChange={handleSearchIngredients}
                  value={ingredientsValue}
                  placeholder="Search"
                  className="preference__search_input"
                  type="text"
                />

                <span
                  ref={clearedIngredientsRef}
                  onClick={removeIngredientsValue}
                  className="cleared-value"
                ></span>
              </div>
              <div ref={ingredientRef} className="ingredients">
                {ingredientsToRender.length > 0
                  ? createItemIngredientsList()
                  : null}
              </div>
              <p
                ref={ingredientsWarningRef}
                className="existed-ingredients__warning"
              >
                No match found. Search again
              </p>
            </div>

            <div
              ref={ingredientsListRef}
              onClick={createIngredientElem}
              className="preference__variables_list hide"
            >
              <ul className="filtered__ingredient_wrapper">
                {filteredIngredients.map((item, index) => (
                  <li className="filtered__ingredient" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <form className="form" onSubmit={handleSubmit}>
            <button
              type="submit"
              onClick={handleOnLoadCheckData}
              className={`create_query ${isButtonDisabled ? "disabled" : ""}`}
            >
              Create
            </button>

            <button ref={resetRef} className="reset" onClick={resetPreferences}>
              <span className="reset__icon">
                <img src={svgIcon} alt="reset svg" />
                <img src={svgIconGrey} alt="reset svg gray" />
              </span>
              <span className="reset__title">Reset</span>
            </button>
          </form>
        </div>
      </div>
      <div ref={overlayPage} className="overlay">
        <div ref={visibilityGeneralPopupRef} className="popup__united hidden">
          <div onClick={handleClosePopup} className="popup__close">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              className="popup__progress_circle"
            >
              <circle className="bg"></circle>
              <circle className="fg"></circle>
            </svg>
          </div>
          <h3 className="popup__title">Opps, no choices yet!</h3>
          <span className="popup__text">
            Select your dish type and ingredients to continue.
          </span>
        </div>

        <div
          ref={visibilityConflictingItemsRef}
          className="popup__united hidden"
        >
          <div onClick={handleClosePopup} className="popup__close">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              className="popup__progress_circle"
            >
              <circle className="bg"></circle>
              <circle className="fg"></circle>
            </svg>
          </div>
          <span className="popup__title">
            Oops, There's a dietary conflict.
          </span>
          {conflicts.diets.length > 0 ? (
            <span className="popup__text">
              Diets:{" "}
              <strong>
                <span className="conflicted__items">
                  {conflicts.diets.join(", ")}
                </span>
              </strong>{" "}
            </span>
          ) : null}
          {conflicts.allergies.length > 0 ? (
            <span className="popup__text">
              Allergies:{" "}
              <strong>
                <span className="conflicted__items">
                  {conflicts.allergies.join(", ")}
                </span>
              </strong>{" "}
            </span>
          ) : null}
          <span className="popup__text">
            Ingredients:{" "}
            <strong>
              <span className="conflicted__items">
                {conflicts.ingredients.join(", ")}
              </span>
            </strong>
          </span>
        </div>

        <div ref={maxAttemptsPopupRef} className="popup__united hidden">
          <div onClick={handleClosePopup} className="popup__close">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              className="popup__progress_circle"
            >
              <circle className="bg"></circle>
              <circle className="fg"></circle>
            </svg>
          </div>
          <h3 className="popup__title">
            Opps, you've reached the daily limit.
          </h3>
          <span className="popup__text">Come back tomorrow and try again.</span>
        </div>
      </div>
    </>
  );
};
export default Preferences;

export const SetConversation = (title) => {
  const userConversation = localStorage.getItem("conversation");

  if (!userConversation) {
    console.error("User not made any query");

    let arr = [];
    arr.push(title);

    localStorage.setItem("conversation", JSON.stringify(arr));
  } else {
    let conversationTitles = JSON.parse(userConversation);
    conversationTitles.push(title);
    localStorage.setItem("conversation", JSON.stringify(conversationTitles));
  }
};
export function CheckAndUpdateRecipeAttempts(attemptsToday) {
  console.log(attemptsToday)
  const userHash = localStorage.getItem('userHash');
  let userData = JSON.parse(localStorage.getItem(userHash));
  const maxAttempts = +localStorage.getItem('max_attempts');

  if (+attemptsToday <= maxAttempts) {
    userData.generated_recipe_today = attemptsToday;
  }

  // Зберігаємо оновлені дані в localStorage
  localStorage.setItem(userHash, JSON.stringify(userData));
}
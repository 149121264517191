import {parseInput} from "../assets/helper";

class HandleLocalData {
  /**
   * test
   */
  prepareAndStoreFavorites(serverData) {
    // парсимо відповідь
    // зберігаємо в сторедж
    const userHash = localStorage.getItem('userHash');

    const arrFavoritesFormatted = serverData.data.map(item => {
      const parsedData = parseInput(item.content)

      return {
        liked: true,
        id: item.recipe_id,
        image: item.banner,
        title: parsedData.title,
        time_preparing: parsedData.time_preparing,
        ingredients: parsedData.ingredients,
        instructions: parsedData.instructions,
        cuisine: item.recipeData.cuisine ? item.recipeData.cuisine : null
      }
    })

    const favorites = {
      'favorites': arrFavoritesFormatted,
      'generated_recipe_today': +localStorage.getItem('generated_recipe_today'),
    }

    if (favorites.length !== 0) {
      localStorage.setItem(userHash, JSON.stringify(favorites))
    } 
  }
}

export default new HandleLocalData();
import "./favorite.css";
import React, { useState, useRef, useEffect } from "react";
import timerImg from "../Recipe/img/timer.svg";
import arrowOpen from "./img/arrow_open.svg";
import { useNavigate } from "react-router-dom";
import RoutesToPages from "../../RoutesToPages";
import ServerRequest from "../../ServerRequests/ServerRequest";

const FavoritesPage = () => {
  const recipeLikeRef = useRef(null);
  const userHash = localStorage.getItem("userHash");
  const userData = JSON.parse(localStorage.getItem(userHash));
  const [generalFavorites, setGeneralFavorites] = useState(
    userData ? userData.favorites : []
  );
  const navigate = useNavigate();
  const [searchedRecipe, setSearchedRecipe] = useState('');
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const clearedRecipeRef = useRef(null);
  const recipeAbsentRef = useRef(null);

  const removeSearchedRecipeValue = () => {
    setSearchedRecipe('');
    setFilteredRecipes([]);
  }

  const handleSearchRecipe = (e) => {
    const target = e.target.value;

    setSearchedRecipe(target.trimStart());

    if (target.trim() === '') {
      setFilteredRecipes(generalFavorites);
      return;
    }

    const filteredFavoritesRecipes = generalFavorites.filter(item => {
      const allWordsTitle = item.title.toLowerCase().split(" ")
        .some(word => {
          return target.toLowerCase().includes(word.toLowerCase());
        })
      const separateRecipeTitlesArr = item.title.toLowerCase().split(" ");

      if (target.toLowerCase().includes(" ") && allWordsTitle) {
        return item.title.toLowerCase().includes(target.toLowerCase())
      } else {
        return separateRecipeTitlesArr.some(eachWordTitle =>
          eachWordTitle.startsWith(target.toLowerCase())
        );
      }
    });

    setFilteredRecipes(filteredFavoritesRecipes);
  };

  const handleChangeLike = (id) => () => {
    const updatedRecipes = generalFavorites.map((item) =>
      item.id === id ? { ...item, liked: !item.liked } : item
    );
    setGeneralFavorites(updatedRecipes);

    const updatedFilteredRecipes = filteredRecipes.map((item) =>
      item.id === id ? {...item, liked: !item.liked} : item
    );
    setFilteredRecipes(updatedFilteredRecipes);

    userData.favorites = updatedRecipes;
    localStorage.setItem(userHash, JSON.stringify(userData));
  };

  useEffect(() => {
    if (generalFavorites.length > 0) {
      const changeLikeStatus = async (id, liked) => {
        try {
          await ServerRequest.changeFavoriteRecipeData(id, liked);
        } catch (error) {
          console.error("Error changing like status:", error);
        }
      };

      generalFavorites.forEach((recipe) => {
        if (recipe.id && typeof recipe.liked !== "undefined") {
          changeLikeStatus(recipe.id, recipe.liked);
        }
      });
    }
  }, [generalFavorites]);

  useEffect(() => {
    if (generalFavorites.length > 0) {
      const actualStoredRecipes = userData.favorites.filter(
        (item) => item.liked
      );
      userData.favorites = actualStoredRecipes;
      localStorage.setItem(userHash, JSON.stringify(userData));
      setGeneralFavorites(actualStoredRecipes);
    }
  }, []);

  const navigateToSingleRecipe = (recipeLiked, recipeId) => () => {
    if (recipeLiked) {
      navigate(RoutesToPages.singleRecipe + recipeId);
    }
  };

  useEffect(() => {
    if (clearedRecipeRef.current) {
      if (searchedRecipe.length > 0) {
        clearedRecipeRef.current.classList.add('activated');
      } else {
        clearedRecipeRef.current.classList.remove('activated');
      }
    }

  }, [searchedRecipe]);

  useEffect(() => {
    if (recipeAbsentRef.current) {
      if (searchedRecipe.length !== 0 && filteredRecipes.length === 0) {
        recipeAbsentRef.current.classList.add('activated-recipe-warning');
      } else {
        recipeAbsentRef.current.classList.remove('activated-recipe-warning');
      }
    }
  }, [filteredRecipes]);

  const recipesToDisplay = searchedRecipe.trim() === '' ?
    generalFavorites : filteredRecipes;

  return generalFavorites && generalFavorites.length > 0 ? (
    <section id="section__wrapper" className="section">
      <div className="section">
        <div className="favorites__page_wrapper">
          <div className="favorites__page">
            <h2 className="favorites__page_title">
              <span>Favorite Recipes</span>
            </h2>

            <div className="input__wrapper-recipes">
              <button className="favorite__search_button">
                <img
                  className="favorite__search_icon"
                  src={require("./img/search.png")}
                  alt="Search icon"
                />
              </button>
              <input
                type="text"
                className="input__favorites"
                value={searchedRecipe}
                onChange={handleSearchRecipe}
                placeholder='Search'/>
              <span
                ref={clearedRecipeRef}
                onClick={removeSearchedRecipeValue}
                className="cleared-recipe-value"
              ></span>
              <p className='absent__recipe'
                 ref={recipeAbsentRef}>
                Oops! No recipe was found.
                <br/>Please try searching for another one.
              </p>
            </div>

            <div className="favorites__box">
              {recipesToDisplay.map((elem, index) => (
                <div className="favorites__recipe" key={index}>
                  {Object.entries(elem).map(([key, value], index) => (
                    <React.Fragment key={index}>
                      {key === "title" && (
                        <>
                          <div className="favorites__title_wrapper">
                            <h3 className="favorites__title">{value}</h3>
                            <div
                              className={
                                elem.liked ? "recipe__liked" : "recipe__like"
                              }
                              ref={recipeLikeRef}
                              onClick={handleChangeLike(elem.id)}
                              id={elem.id}
                            ></div>
                          </div>
                        </>
                      )}

                      {key === "cuisine" && (
                        value ? <span className="cuisine__favorite">
                        {`${value} cuisine`}</span> : null
                      )}

                      {key === "ingredients" && (
                        <>
                          <div className="ingredients__wrapper">
                            <span className="ingredients">Ingredients:</span>
                            <ul className="ingredients__list">
                              {value.slice(0, 3).map((item, index) => (
                                <li className="ul" key={index}>
                                  {index === value.slice(0, 3).length - 1
                                    ? item.concat(" ...")
                                    : item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      )}

                      {key === "time_preparing" && (
                        <>
                          <div className="divider__line" />
                          <div className="interaction__receipt">
                            <div className="receipt__time">
                              <span className="time">
                                <span>
                                  <img className="icon__time" src={timerImg} />
                                </span>
                                {value}
                              </span>
                            </div>{" "}
                            <a
                              onClick={navigateToSingleRecipe(
                                elem.liked,
                                elem.id
                              )}
                              className={`open__receipt ${
                                !elem.liked ? "hide__arrow" : ""
                              }`}
                              id={elem.id}
                            >
                              <img src={arrowOpen} className="arrow" />
                            </a>
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="favorite__absent">
      Nothing here yet!
      <br /> Like recipes to see them in this tab.
    </div>
  );
};

export default FavoritesPage;
